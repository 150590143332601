import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Card } from 'antd';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import styles from './service-infos.module.css';

export default ({ data: { infos } }) => {
  return (
    <Layout>
      <div className={styles.root}>
        <h1>Service & Infos</h1>
        {infos.edges.sort((a, b) => a.node.name.localeCompare(b.node.name)).map(({ node: { icon, name, header, content } }) => {
          const { html } = content ? content.childMarkdownRemark : {};
          return (
            <Card key={name} className={styles.card} hoverable>
              <Card.Meta
                avatar={<LegacyIcon type={icon} />}
                title={<h3>{name}</h3>}
                description={
                  <div>
                    <h4>{header}</h4>
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                  </div>
                }
              />
            </Card>
          );
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ServiceInfosPageQuery {
    infos: allContentfulInfos {
      edges {
        node {
          name
          header
          icon
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
